// router.js
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/", name: "主页", component: () => import("@/views/homePage.vue") },
  {
    path: "/home",
    name: "首页",
    component: () => import("@/views/homePage.vue"),
  },
  {
    path: "/scanCode",
    name: "扫码",
    component: () => import("@/views/testS.vue"),
  },
  // {
  //   path: "/test",
  //   name: "测试扫码",
  //   component: () => import("@/views/testS.vue"),
  // },
];

const router = createRouter({
  // eslint-disable-next-line no-undef
  history: createWebHistory(
    window.__MICRO_APP_BASE_ROUTE__ || process.env.BASE_URL
  ),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
