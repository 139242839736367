<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
